import { format } from 'date-fns';
import { formatSearchPath, getImageUrl } from '../services/util';

class {
  onInput() {
    let activeIndex = 0;

    this.state = { activeIndex };
  }

  setActiveIndex(newActiveIndex) {
    this.state.activeIndex = newActiveIndex;
  }

  handleHighlightClick(highlightIndex, event) {
    this.setActiveIndex(highlightIndex);
    event.preventDefault();
  }
}

style.scss {
  @use '../style/mixins' as mx;

  .ht-highlights {
    @include mx.mq(max-large) {
      padding: 2rem 0;
    }
    @include mx.mq(large) {
      padding: 4rem 0;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;

      @include mx.mq(max-large) {
        padding-top: 2rem;
      }
      @include mx.mq(large) {
        padding-top: 5rem;
      }
    }
    &__item {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 1rem;
      border: 1px solid var(--color-primary-darker);
      border-radius: var(--global-radius);
      background-color: transparent;
      color: var(--color-primary-darker);
      font-size: 1rem;
      text-align: left;
      cursor: pointer;

      @include mx.mq(max-medium) {
        width: 100%;
      }
      @include mx.mq(medium-large) {
        width: calc(50% - 0.5rem);
        height: 100px;
      }
      @include mx.mq(large) {
        width: calc(25% - (1rem / 1.334));
        height: 116px;
      }

      &:hover,
      &.active {
        border-color: var(--color-primary-darkest);
        background-color: var(--color-primary-a20);
        color: var(--color-primary-darkest);
        text-decoration: none;
      }
    }
  }
  .hero {
    gap: 2rem;
    min-height: 300px;

    @include mx.mq(max-medium) {
      flex-direction: column;
    }

    &:not(.active) {
      display: none;
    }
    &.active {
      display: flex;
    }
    a {
      color: var(--color-primary-darker);

      &:hover {
        color: var(--color-primary-darkest);
      }
    }
    &__left {
      flex: 0 0 33%;
    }
    &__banner {
      width: 100%;
      border: 1px solid var(--color-primary-darker);
      border-radius: 5px 5px 0 0;
    }
    &__more-link {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      width: 100%;
      margin-top: 0.5rem;
      padding: 1rem;
      border: 1px solid var(--color-primary-darker);
      border-radius: 0 0 5px 5px;
      font-size: 1.25rem;
      text-transform: uppercase;
      line-height: 1;

      &:hover {
        border-color: var(--color-primary-darkest);
        background-color: var(--color-primary-a20);
        text-decoration: none;
      }
    }
    &__right {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
    }
    &__top-line {
      position: relative;
      text-align: right;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        width: calc(100% - 38px);
        border-top: 1px solid var(--color-primary-darker);
        transform: translateY(-50%);
      }
    }
    &__arrow-link {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 38px;
      height: 38px;
      border: 1px solid var(--color-primary-darker);
      border-radius: 100%;

      &:hover {
        background-color: var(--color-primary-a20);
        text-decoration: none;
      }
    }
    &__title {
      display: block;
      padding: 1rem 0;
      font-size: 2rem;
    }
    &__key-info {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 2rem;
      margin-top: auto;
      padding-top: 2rem;

      &__location {
        font-size: 1.5rem;
        font-weight: 300;
      }
      &__separator {
        height: 100%;
        border-left: 1px solid var(--color-primary-darker);
      }
      &__date {
        display: flex;
        gap: 0.5rem;
      }
    }
    .date {
      position: relative;

      &__icon {
        font-size: 4.5rem;
      }
      &__title {
        position: absolute;
        top: -1.5rem;
        left: 50%;
        transform: translateX(-50%);
      }
      &__calendar {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: absolute;
        top: 54%;
        left: 50%;
        line-height: 1.2;
        transform: translate(-50%, -50%);
      }
      &__day {
        font-size: 1.4rem;
      }
      &__month {
        font-size: 0.8rem;
        text-transform: uppercase;
      }
    }
  }
}

<section class="ht-highlights">
  <div class="ht-highlights__hero container">
    <for|highlight, index| of=input.highlights>
      <div
        class=['hero', { active: state.activeIndex === index }]
      >
        <div class="hero__left">
          <img
            class="hero__banner"
            src=highlight.imageBanner ?
              getImageUrl(highlight.imageBanner, {width: 400, crop: 'fill', quality: 'auto:eco', fetch_format: 'auto', dpr: 'auto'}) :
              highlight.logo
            alt=""
          />
          <a
            class="hero__more-link"
            href=`/event/${highlight.slug}`
          >
            More details
            <ht-icon name="idea-ht-arrow-right"/>
          </a>
        </div>
        <div class="hero__right">
          <div class="hero__top-line">
            <a
              class="hero__arrow-link"
              href=`/event/${highlight.slug}`
              aria-label="See event page"
            >
              <ht-icon name="idea-ht-arrow-right"/>
            </a>
          </div>
          <a
            class="hero__title roboto-300"
            href=`/event/${highlight.slug}`
          >
            ${highlight.name}
          </a>
          <ht-event-topics topics=highlight.topic/>
          <div class="hero__key-info">
            <if(highlight.city && highlight.country)>
              <div class="hero__key-info__location">
                <ht-icon name="idea-ht-location" size=1.5/> 
                <a href=formatSearchPath({ type: 'city', city: highlight.city, country: highlight.country })><strong>${highlight.city}</strong></a>, 
                <a href=formatSearchPath({ type: 'country', searchTerm: highlight.country})>${highlight.country}</a>
              </div>
            </if>
            <else>
              <div class="hero__key-info__location">
                <ht-icon name="idea-ht-online" size=1.5/> <a href=formatSearchPath({ type: 'online' })>Online</a>
              </div>
            </else>
            <div class="hero__key-info__separator"></div>
            <div class="hero__key-info__date">
              <if(highlight.start)>
                <div class="date date--start">
                  <ht-icon name="idea-ht-calendar-light" size=4/>
                  <div class="date__title">Starts</div>
                  <div class="date__calendar">
                    <div class="date__day">${format(highlight.start, 'dd')}</div>
                    <div class="date__month">${format(highlight.start, 'MMM')}</div>
                  </div>
                </div>
                <if(highlight.stop && highlight.stop != highlight.start)>
                  <div class="date date--end">
                    <ht-icon name="idea-ht-calendar-light" size=4/>
                    <div class="date__title">Ends</div>
                    <div class="date__calendar">
                      <div class="date__day">${format(highlight.stop, 'dd')}</div>
                      <div class="date__month">${format(highlight.stop, 'MMM')}</div>
                    </div>
                  </div>
                </if>
              </if>
              <else>
                <div class="date date--start">
                  <ht-icon name="idea-ht-calendar-light" size=4/>
                  <div class="date__title">Starts</div>
                  <div class="date__calendar">
                    <div class="date__day">${format(highlight.date_start, 'dd')}</div>
                    <div class="date__month">${format(highlight.date_start, 'MMM')}</div>
                  </div>
                </div>
                <if(highlight.date_end && highlight.date_end != highlight.date_start)>
                  <div class="date date--end">
                    <ht-icon name="idea-ht-calendar-light" size=4/>
                    <div class="date__title">Ends</div>
                    <div class="date__calendar">
                      <div class="date__day">${format(highlight.date_end, 'dd')}</div>
                      <div class="date__month">${format(highlight.date_end, 'MMM')}</div>
                    </div>
                  </div>
                </if>
              </else>
            </div>
          </div>
        </div>
      </div>
      <!-- ${JSON.stringify(highlight)} -->
    </for>
  </div>
  <div class="ht-highlights__list container">
    <for|highlight, index| of=input.highlights>
      <button
        class=['ht-highlights__item', { active: state.activeIndex === index }]
        on-click('handleHighlightClick', index)
      >
        <span class="ht-highlights__item__name">
          ${highlight.name}
        </span>
        <span class="ht-highlights__item__date">
          <ht-icon name="idea-ht-calendar-2"/> ${format(highlight.date_start, 'do LLLL yyyy')}
        </span>
      </button>
    </for>
  </div>
</section>
