style.scss {
  .ht-event-topics {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
    margin: 0;
    padding: 0;
    list-style: none;

    & > li {
      flex: 0 0 auto;
    }
    &__tag {
      display: block;
      padding: 0.25rem 0.5rem;
      border: 1px solid var(--grey);
      color: var(--grey);

      &:hover {
        border-color: var(--color-primary-darker);
        background-color: var(--color-primary-a20);
        color: var(--color-primary-darker);
        text-decoration: none;
      }
    }
  }
}

<ul class="ht-event-topics">
  <for|topic| of=input.topics>
    <li>
      <a
        class="ht-event-topics__tag"
        href=`/theme/${topic.toLowerCase()}`
      >
        ${topic}
      </a>
    </li>
  </for>
</ul>
